<template>
    <div>
        <!--<div class="head-back"></div>-->
        <img class="logo" src="../assets/logo.png">
        <ul class="nav">
            <li class="clinkclick" @click="clinkclick()">clinkclick</li>
            <li class="service-advantage" @click="serviceAdvantage()">Service advantage</li>
            <li class="case" @click="caseClick()">Case</li>
            <li class="cooperation" @click="toBottom()">Cooperation</li>
            <li class="contact-us" @click="toBottom()">contact us</li>
        </ul>

        <img class="detail" src="../assets/cinkclick-en_01_new.jpg">
        <img class="detail" src="../assets/cinkclick-en_02.jpg">
        <img class="detail" src="../assets/cinkclick-en_03.jpg">
        <img class="detail" src="../assets/cinkclick-en_04.jpg">
        <img class="detail" src="../assets/cinkclick-en_05.jpg">
    </div>
</template>

<script>
    export default {
        name: 'HelloWorld',
        props: {
            msg: String
        },

        methods:{
            clinkclick(){
                document.documentElement.scrollTop = 0;
            },
            serviceAdvantage(){
                document.documentElement.scrollTop = 450;
            },
            caseClick(){
                document.documentElement.scrollTop = 4040;
            },
            toBottom(){
                document.documentElement.scrollTop = 5000;
            },
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    /* 头部黑色背景 */
    .head-back {
        background-color: black;
        width: 100%;
        height: 10%;
        position: absolute;
    }

    .logo {
        width: 7%;
        height: 1.5%;
        position: absolute;
        top: 2%;
        left: 12.5%;
    }

    /* 导航栏 */
    .nav {
        list-style: none;
        width: 34.6%;
        height: 2%;
        /*background-color: red;*/
        position: absolute;
        top: 2%;
        left: 52.9%;
        display: flex;
        justify-content: space-between;

    }

    li {
        color: white;
        color: rgba(255, 255, 255, 0.8);
        font-size: 13px;
        font-weight: 200;
        font-family: Arial;
        cursor: pointer;
    }

    li:hover {
        color: rgba(255, 255, 255, 1);
        font-weight: 300;

    }

    .service-advantage {
        margin-left: -3%;
    }

    .case {
        margin-left: -3%;
    }

    .detail {
        width: 100%;
        display: block;
    }


</style>
